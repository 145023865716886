import React, { useEffect, useMemo, useState, useRef, lazy, Suspense } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Divider } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { BaseRate } from '../common/BaseRate/BaseRate';
import { useTranslation } from 'react-i18next';
import { getServiceProviderData } from './../../api/serviceprovider.api';
import { notificationController } from './../../controllers/notificationController';
import * as Z from './../nft-dashboard/recentActivity/recentActivityFeed/RecentActivityItem/RecentActivityItem.styles';
import { useAppSelector } from './../../hooks/reduxHooks';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

import { BaseSpin } from './../../components/common/BaseSpin/BaseSpin';
import { BaseModal } from './../../components/common/BaseModal/BaseModal';
import TextField from '@mui/material/TextField';
import { BaseSelect, Option } from './../../components/common/selects/BaseSelect/BaseSelect';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import * as htmlToImage from 'html-to-image';
import serviceimg from './../../assets/serviceimg.jpg';
import { BaseRadio } from '../common/BaseRadio/BaseRadio';
import { readClientIp, readCommunityCode } from './../../services/localStorage.service';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { addReviewFeedback } from '../../api/reviewfeedback.api';
import { useAppDispatch } from './../../hooks/reduxHooks';
import { ProviderForm } from './../../components/ProviderForm/ProviderForm';
import SendIcon from '@mui/icons-material/Send';
import logo from './../../assets/logogo.png';
import { BaseArticle } from '../common/BaseArticle/BaseArticle';
import { PromoForm } from '../ProviderForm/PromoForm';
import { Watermark } from 'antd';
import { color } from 'echarts';
//import { TopDealsCard } from '../deals-dashboard/topDeals/TopDealsCard/TopDealsCard';



const style = {
    width: '100%',
    //   maxWidth: 360,
    bgcolor: 'background.paper',
};
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const keyValue = {
    landscaping: "Land scaping"
}


const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip;

const feedbackItems: any = [{ id: "1", code: "no-response", name: "No Response" },
{ id: "1", code: "quality-not-good", name: "Service Quality is not Good" },
{ id: "1", code: "rate-is-very-high", name: "Rate is very High" }];

// ./../../assets/serviceimg.jpg
const Icon = (props: any) => {
    const src: any = lazy(() => import("./../../assets/" + '' + "serviceimg.jpg"));
    return (
        <Suspense fallback={<p>loading...</p>}><img src={src} /></Suspense>
    );
};


const children: React.ReactNode[] = [];

feedbackItems.map((obj: any) => {
    children.push(
        <Option key={obj.code.toString()} value={obj.name.toString()}>
            {obj.name}
        </Option>,
    );

})





export default function MediaControlCard(props: any) {
    const theme = useTheme();
    const urlLink = window.location.pathname;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isMiddleModalOpen, setIsMiddleModalOpen] = useState<boolean>(false);
    const [isMiddleModalFeedOpen, setIsMiddleModalFeedOpen] = useState<boolean>(false);
    const [isBusinessModalOpen, setIsBusinessModalOpen] = useState<boolean>(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
    const [isPromoModalOpen, setIsPromoModalOpen] = useState<boolean>(false);
    const [pdfContent, setPdfContent] = useState('');
    const [serviceDetail, setServiceDetail] = useState<any>();
    const imgref = useRef<HTMLDivElement>(null);
    const componentRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    let portalDiv = document.getElementById("imgid") as HTMLElement;


    //   comm

    const handleDetail = async (comm: any, status: any) => {
        setIsMiddleModalOpen(status);
        setServiceDetail(comm);

    }

    const handleBusinessDetail = async (status: any) => {
        setIsBusinessModalOpen(status);
    }

    const handleReviewsDetail = async (comm: any, status: any) => {
        setIsReviewModalOpen(status);
        setServiceDetail(comm);
    }

    const handlePromoDetail = async (comm: any, status: any) => {
        setIsPromoModalOpen(status);
        setServiceDetail(comm);
    }

    const handleProDetail = async (status: any) => {
        setIsPromoModalOpen(status);
    }


    const handleDetailFeed = async (comm: any, status: any) => {

        setSelectedValue('');
        setSelectedRecomValue('');
        setSelectedComments('');
        setReason('');
        setRating('')
        setIsMiddleModalFeedOpen(status);
        setServiceDetail(comm);
    }

    const setRateValue = (e: any) => {

        setRating(e);
    }

    const handleShare = async () => {
        const response = await fetch(logo);
        // here image is url/location of image
        const blob = await response.blob();
        const file = new File([blob], 'share.jpg', { type: blob.type });
        console.log(file);
        if (navigator.share) {
            await navigator.share({
                title: "title",
                text: "your text",
                url: "url to share",
                files: [file]
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error in sharing', error));
        } else {
            console.log(`system does not support sharing files.`);
        }
    }

    const handleShares = async () => {

        const nd: any = await htmlToImage.toBlob(portalDiv);
        const data = {

            files: [
                new File([nd], 'image.png', {
                    type: nd.type,
                }),
            ],
            title: 'image',
            text: 'image'

        }

        try {
            await navigator.share(data);
        } catch (err) {
            console.error(err);
        }
    }

    /* const generatePdf = async () => {
      try {
        const response = await axios.post('http://localhost:5000/api/v1/community/generate', { content: pdfContent }, { responseType: 'blob' });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'generated.pdf';
        link.click();
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }; */

    const { type } = props;

    const [data, setData] = useState([]);
    const user: any = useAppSelector((state: any) => state.user.user);
    const commCode = readCommunityCode();
    const communityCode = commCode?.code || user?.community?.code;



    const [inputValue, setInputValue] = React.useState('');

    const [codeValue, setCodeValue] = React.useState('');

    const onTagsChange = (event: any, values: any) => {
        if (values === null) {
            // persistCommunityCode(null)
        } else {

            setInputValue(values.code);
            setCodeValue(values.code)
            //  persistCommunityCode({code: values.code, name: values.name})
        }

    }


    const [selectedValue, setSelectedValue] = React.useState('');
    const [selectedRecomValue, setSelectedRecomValue] = React.useState('');
    const [selectedRecomVisible, setSelectedRecomVisible] = React.useState('');
    const [selectedComments, setSelectedComments] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [rating, setRating] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const handleChangeRecom = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRecomValue(event.target.value);
    };

    const handleChangeRecomVisible = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRecomVisible(event.target.value);
    };

    const handleChangeReason = (event: SelectChangeEvent) => {
        setReason(event.target.value);
    };

    const controlProps = (item: string, handleChangeClick: any, selectedVal: any, nameval: any) => ({
        checked: selectedVal === item,
        onChange: handleChangeClick,
        value: item,
        name: nameval,
        inputProps: { 'aria-label': item },
    });

    const handleReviewSubmit = () => {

        if (selectedValue === 'yes') {

            const req = {
                availservice: 'yes',
                rating: rating,
                recommendToOther: selectedRecomValue,
                comments: selectedComments,
                vendorId: serviceDetail.code,
                userId: user.id,
                userVisisble: (selectedRecomVisible === 'yes' ? true : false)
            };

            setLoading(true);
            addReviewFeedback(req)
                .then(() => {
                    notificationController.success({
                        message: t('feedback.addFeedbackMessage')
                    });
                    setIsMiddleModalFeedOpen(false);
                    setLoading(false);
                    window.location.href = location.pathname;
                })
                .catch((err: any) => {
                    notificationController.error({ message: err.message });
                    setLoading(false);
                    setIsMiddleModalFeedOpen(false);
                });



        }

        if (selectedValue === 'no') {

            const req = {
                availservice: 'no',
                comments: selectedComments,
                reason: reason,
                vendorId: serviceDetail.code,
                userId: user.id
            }

            setLoading(true);
            addReviewFeedback(req)
                .then(() => {
                    notificationController.success({
                        message: t('feedback.addFeedbackMessage')
                    });
                    setIsMiddleModalFeedOpen(false);
                    setLoading(false);
                })
                .catch((err: any) => {
                    notificationController.error({ message: err.message });
                    setLoading(false);
                    setIsMiddleModalFeedOpen(false);
                });

        }

    };

    useEffect(() => {
        setLoading(true);

        const inpReq = {
            serviceType: type,
            communityCode: communityCode || 'auburn-mckinney-tx',
            userId: (user?.username || 'Guest'),
            city: user?.community?.city,
            region: user?.community?.state,
            country: 'US',
            ip: '0.0.0.0',
            postal: user?.community?.postalcode,
            latitude: user?.community?.latitude,
            longitude: user?.community?.longitude,
            timezone: user?.community?.timeZone,

        }
        getServiceProviderData(inpReq)
            .then((res: any) => {
                setLoading(false);
                setData(res);
            })
            .catch((err) => {
                notificationController.error({ message: err.message });
                setLoading(false);
            });


        /* 
        
        
        
        
        
        
        
                setLoading(true);
                const inpReqa = {
                    serviceType:type,
                    communityCode: communityCode,
                    userId: user.username
                }
                getServiceProviderData(type, communityCode)
                    .then((res) => {
                        setLoading(false);
                        setData(res);
                    })
                    .catch((err: any) => {
                        notificationController.error({ message: err.message });
                        setLoading(false);
                    }); */
    }, [type]);

    return isLoading ? <BaseSpin /> : (

        <div>

            {/* <div onClick={() => handleBusinessDetail(true)} style={{ textAlign: 'right' }} >
                <div style={{ fontSize: '16px', color: '#003773' }}>{'Please send your business details to info@servicetoget.com or '}
                    <Button style={{ background: 'green' }} variant="contained" endIcon={<SendIcon />}>
                        Post your Business
                    </Button></div>

            </div> */}


            <div style={{ height: '10px' }}>&nbsp;</div>


            {/*  <TopDealsCard />  */}

            <Typography gutterBottom component="div" variant="h6">
                <>{t('common.' + type)}</>
            </Typography>
            {data && data.length > 0 && data.map((comm: any) => (


                <div key={comm.id}>

                    <Z.ActivityCard style={{ backgroundColor: '#FFF', borderStyle: 'groove', borderColor: '#1c2841' }}>
                        <Z.Wrapper>
                            <Z.ImgWrapper>
                                <img src={comm.imageURL || serviceimg} alt={'title'} width={84} height={84} />
                            </Z.ImgWrapper>

                            <Z.InfoWrapper>
                                <Z.InfoHeaderWrapper>
                                    <Z.TitleWrapper>
                                        <Z.Title level={5}>
                                            <Typography gutterBottom component="div" variant="h6">
                                                {user?.firstName ? (<> {
                                                    comm.discounts && comm.discounts.length > 0 ? (
                                                        <div>{comm.name + '  '}<p style={{ color: 'red', fontSize: '14px' }}>
                                                             {comm.discounts[0].discounttype === 'percent' ? comm.discounts[0].discountvalue + '% OFF' : '$' + comm.discounts[0].discountvalue + ' OFF'}
                                                            <span style={{ color: 'red' }}>
                                                                <BaseButton style={{ background: 'red' }} onClick={() => handlePromoDetail(comm, true)} type="primary">{'Click here for Promo code'}</BaseButton>

                                                            </span> 
                                                        </p>

                                                        </div>
                                                    ) : <div>{comm.name}</div>
                                                }</>

                                                ) :
                                                    <>{'XXXXX'}</>}
                                            </Typography>
                                            {type === 'indian' && comm.ispromo ? (<div onClick={() => handlePromoDetail(comm, true)} style={{ cursor: 'pointer', color: 'green' }}>{/* Click here For Promo Code(5%) */}</div>) : (<></>)}

                                        </Z.Title>
                                    </Z.TitleWrapper>
                                    <span onClick={() => handleReviewsDetail(comm, true)} style={{ cursor: 'pointer', marginTop: '-5px', fontSize: '18px', textDecoration: 'underline' }}><BaseRate allowHalf disabled defaultValue={Number(comm.rating)} />&nbsp;{'Ratings(' + (comm?.reviews?.length || 0) + ')'}</span>
                                    {user?.firstName ? (

                                        /*  <p> {<BaseButton onClick={() => setIsMiddleModalOpen(true)} type="primary">{'Contact Details'}</BaseButton> }
                                         <span>{<BaseButton onClick={() => setIsMiddleModalOpen(true)} type="primary">{'Contact Details'}</BaseButton>}</span> </p> */
                                        <Box sx={{ flexGrow: 1 }}>
                                            <div>&nbsp;</div>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <BaseButton style={{ background: '1c2841' }} onClick={() => handleDetail(comm, true)} type="primary">{'Details'}</BaseButton>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <BaseButton onClick={() => handleDetailFeed(comm, true)} type="primary">{'Add Review'}</BaseButton>
                                                </Grid>
                                            </Grid>

                                            <BaseModal
                                                title={t('modals.contactDeatils')}
                                                centered
                                                open={isMiddleModalOpen}
                                                onOk={() => setIsMiddleModalOpen(false)}
                                                onCancel={() => setIsMiddleModalOpen(false)}
                                                size="medium"
                                                maskStyle={{
                                                    backgroundColor: "transparent"
                                                }}
                                                style={{ background: '#FFF' }}
                                            // width={'60%'}
                                            >

                                                {/*     <Watermark content={['SERVICETOGET']}  rotate={-95} font={{
                                                color:'green',
                                           
                                               }} > */}

                                                <Watermark content="ServiceGate">


                                                    <div style={{ border: '1px solid', margin: '10px 10px 20px' }}>
                                                        <div style={{ margin: '10px 20px 20px' }}>



                                                            <p><Typography gutterBottom component="div" variant="h6">
                                                                {serviceDetail?.name}{' ' + type.toUpperCase()}

                                                            </Typography></p>

                                                            <p>{'Contact Person:' + serviceDetail?.contactperson}</p>
                                                            <p>{'Address : ' + serviceDetail?.address + serviceDetail?.address1 + ' ' + serviceDetail?.city + ' ' + serviceDetail?.state + ' ' + serviceDetail?.postalcode}</p>
                                                            <p>{(serviceDetail?.email ? 'Email: ' + serviceDetail?.email : 'Email: N/A')}</p>

                                                            <p>{' Phone Number: '}<a href={"tel:" + serviceDetail?.phoneNumber}>{serviceDetail?.phoneNumber}</a></p>
                                                            <p>{'Mobile # '} <a href={"tel:" + serviceDetail?.mobile}>{serviceDetail?.mobile}</a></p>
                                                            <p><a href={serviceDetail?.website} target="_blank" rel="noopener noreferrer">{serviceDetail?.website ? serviceDetail?.website : 'NA'}</a>&nbsp;</p>
                                                            <p><img src={logo} width={120} /></p>
                                                            {serviceDetail?.types && serviceDetail?.types.length > 0 && (
                                                                <div><p>Service offered</p>
                                                                    <p>&nbsp;</p></div>

                                                            )}

                                                            {serviceDetail?.types?.map((typ: any) => (

                                                                <BaseRadio defaultChecked={true} >{typ.name}</BaseRadio>

                                                            ))}

                                                        </div>
                                                    </div>

                                                </Watermark>
                                                {/*   </Watermark> */}
                                            </BaseModal>




                                            <BaseModal
                                                title={'Review Details'}
                                                centered
                                                open={isReviewModalOpen}
                                                onOk={() => setIsReviewModalOpen(false)}
                                                onCancel={() => setIsReviewModalOpen(false)}
                                                size="medium"
                                                maskStyle={{
                                                    backgroundColor: "transparent"
                                                }}
                                                style={{ background: '#FFF' }}
                                            >

                                                <div>

                                                    {serviceDetail && serviceDetail.reviews && serviceDetail.reviews.map((rev: any) => (
                                                        <BaseArticle
                                                            key={0}
                                                            title={rev.communityname}
                                                            description={rev.comments}
                                                            date={rev.createdAt}
                                                            imgUrl={"post.img"}
                                                            author={rev.userVisisble ? rev.firstName : 'Resident'}
                                                            avatar={'https://t3.ftcdn.net/jpg/04/65/28/08/360_F_465280897_8nL6xlvBwUcLYIQBmyX0GO9fQjDwNYtV.jpg'}
                                                            rating={rev.rating}

                                                        />

                                                    ))

                                                    }




                                                </div>

                                            </BaseModal>






                                            <BaseModal
                                                title={'Generate Promo Code'}
                                                centered
                                                open={isPromoModalOpen}
                                                //   onOk={() => setIsPromoModalOpen(false)}
                                                okButtonProps={{ style: { display: 'none' } }}
                                                onCancel={() => setIsPromoModalOpen(false)}
                                                cancelButtonProps={{ style: { display: 'none' } }}
                                                size="medium"
                                                //     okText="Send Promo Code"
                                                maskStyle={{
                                                    backgroundColor: "transparent"
                                                }}
                                                style={{ background: '#FFF' }}
                                            >

                                                <div>

                                                    <PromoForm serviceDetail={serviceDetail} handleProDetail={handleProDetail} />
                                                </div>

                                            </BaseModal>


                                            <BaseModal
                                                title={t('modals.addreview')}
                                                centered
                                                open={isMiddleModalFeedOpen}
                                                maskStyle={{
                                                    backgroundColor: "transparent"
                                                }}
                                                onOk={() => handleReviewSubmit()}
                                                onCancel={() => setIsMiddleModalFeedOpen(false)}
                                                okText="Submit"
                                                size="medium"
                                                style={{ background: '#FFF' }}
                                            >

                                                <div style={{ border: '1px solid', margin: '10px 10px 20px' }}>
                                                    <Box style={{ margin: '10px 10px 20px' }} sx={{ flexGrow: 1 }}><Grid container spacing={2}>
                                                        <Grid item xs={12} md={12}>
                                                            1. Did you avail the Service
                                                        </Grid>
                                                        <Grid item xs={12} md={12}>
                                                            <div>


                                                                <Radio
                                                                    {...controlProps('yes', handleChange, selectedValue, 'size-radio-button-demo')}
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 28,
                                                                        },
                                                                    }}
                                                                />
                                                                <label>Yes</label>
                                                                <Radio
                                                                    {...controlProps('no', handleChange, selectedValue, 'size-radio-button-demo')}
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 28,
                                                                        },
                                                                    }}
                                                                />
                                                                <label>No</label>
                                                            </div>

                                                        </Grid>


                                                        {selectedValue === 'yes' && (<>



                                                            <Grid item xs={12} md={12}>
                                                                2. How was the service?
                                                            </Grid>



                                                            <Grid item xs={12} md={12}>
                                                                <div style={{ marginLeft: '10px' }}>
                                                                    <BaseRate allowHalf onChange={(e) => setRateValue(e)} />
                                                                </div>
                                                            </Grid>


                                                            <Grid item xs={12} md={12}>
                                                                3. Will you recommend to others?
                                                            </Grid>

                                                            <Grid item xs={12} md={12}>
                                                                <div>


                                                                    <Radio
                                                                        {...controlProps('yes', handleChangeRecom, selectedRecomValue, 'recommend-radio-button-demo')}
                                                                        sx={{
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                    <label>Yes</label>
                                                                    <Radio
                                                                        {...controlProps('no', handleChangeRecom, selectedRecomValue, 'recommend-radio-button-demo')}
                                                                        sx={{
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                    <label>No</label>
                                                                </div>

                                                            </Grid>
                                                            <Grid item xs={9} md={10}>
                                                                4.  Do you want display your name in Review comments?
                                                            </Grid>

                                                            <Grid item xs={12} md={12}>
                                                                <div>


                                                                    <Radio
                                                                        {...controlProps('yes', handleChangeRecomVisible, selectedRecomVisible, 'recommend-radio-button-demo')}
                                                                        sx={{
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                    <label>Yes</label>
                                                                    <Radio
                                                                        {...controlProps('no', handleChangeRecomVisible, selectedRecomVisible, 'recommend-radio-button-demo')}
                                                                        sx={{
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                    <label>No</label>
                                                                </div>

                                                            </Grid>

                                                            <Grid item xs={9} md={10}>
                                                                <label >
                                                                    5. Comments
                                                                </label>

                                                            </Grid>

                                                            <Grid item xs={12} md={12}>
                                                                <div style={{ marginLeft: '10px' }}>

                                                                    <TextField
                                                                        id="outlined-multiline-static"
                                                                        label="Comments"
                                                                        multiline
                                                                        rows={3}
                                                                        defaultValue=""
                                                                        fullWidth
                                                                        onChange={(e) => setSelectedComments(e.target.value)}
                                                                    />
                                                                </div>

                                                            </Grid>

                                                        </>)
                                                        }



                                                        {selectedValue === 'no' && (<>

                                                            <Grid item xs={12} md={12}>
                                                                2. Please select the reason
                                                            </Grid>

                                                            <Grid item xs={12} md={12}>

                                                                <FormControl sx={{ m: 1, minWidth: 250 }}>
                                                                    <InputLabel id="demo-simple-select-helper-label" >Reason</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-helper-label"
                                                                        id="demo-simple-select-helper"
                                                                        value={reason}
                                                                        label="Reason"
                                                                        onChange={handleChangeReason}
                                                                        fullWidth
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>-</em>
                                                                        </MenuItem>
                                                                        <MenuItem value={'Unable to contact'}>Unable to contact</MenuItem>
                                                                        <MenuItem value={'Responded late and I cancelled'}>Responded late and I cancelled</MenuItem>
                                                                        <MenuItem value={'other'}>Other</MenuItem>
                                                                    </Select>

                                                                </FormControl>

                                                            </Grid>


                                                            <Grid item xs={9} md={10}>
                                                                <label >
                                                                    3. Comments
                                                                </label>

                                                            </Grid>



                                                            <Grid item xs={12} md={12}>
                                                                <div style={{ marginLeft: '10px' }}>

                                                                    <TextField
                                                                        id="outlined-multiline-static"
                                                                        label="Comments"
                                                                        multiline
                                                                        rows={3}
                                                                        defaultValue=""
                                                                        fullWidth
                                                                        onChange={(e) => setSelectedComments(e.target.value)}
                                                                    />
                                                                </div>

                                                            </Grid>


                                                        </>)
                                                        }

                                                    </Grid>
                                                    </Box>

                                                </div>

                                            </BaseModal>
                                        </Box>
                                    ) : <div>
                                        <Link to={'/auth/login?urlLink=' + urlLink || ''}>{<BaseButton type="primary">{'Log In for Details'}</BaseButton>}</Link>
                                    </div>
                                    }
                                </Z.InfoHeaderWrapper>
                            </Z.InfoWrapper>
                        </Z.Wrapper>
                    </Z.ActivityCard>
                    <Divider style={{ height: '5px' }} />



                </div>
            ))}


            {data && data.length === 0 && (
                <div style={{ color: 'blue', fontSize: '15px' }}>Information Will be available very soon</div>

            )}
            <BaseModal
                title={t('Form')}
                centered
                open={isBusinessModalOpen}
                onOk={() => setIsBusinessModalOpen(false)}
                onCancel={() => setIsBusinessModalOpen(false)}
                okText="Sign Up"
                maskStyle={{
                    backgroundColor: "transparent"
                }}
                style={{ background: '#FFF' }}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            // width={'60%'}
            >
                <ProviderForm handleBusinessDetail={handleBusinessDetail} />

            </BaseModal>


        </div>
    );

}


